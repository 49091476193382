const header = document.getElementById("site-header")!;

document.querySelectorAll<HTMLElement>("[data-scroll-to]").forEach((link) => {
  link.addEventListener("click", (e) => {
    // Cancel the native event
    e.preventDefault();

    // Get the target element; do nothing if it does not exist
    const targetSelector = link.dataset["scrollTo"]!;
    const targetElement = document.querySelector<HTMLElement>(targetSelector);
    if (!targetElement) return;

    // Calculate the target scroll position
    const isSideNav = getComputedStyle(header).position === "fixed";
    const headerHeight = isSideNav ? 0 : header.offsetHeight;
    const padding = isSideNav ? header.offsetTop : headerHeight;
    const targetScrollPosition = targetElement.offsetTop - headerHeight - padding;

    // Scroll the window to the target position
    window.scrollTo({ top: targetScrollPosition, behavior: "smooth" });
  });
});
