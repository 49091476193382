/**
 * Enhance '.sticky-top' components to add a '.stuck-top' class when the sticky behavior is active
 *
 * NOTE: This is a fairly naive implementation that will only work for elements stuck to the top of the screen. For a
 * more comprehensive solution see: https://developers.google.com/web/updates/2017/09/sticky-headers
 */

import { debounce } from "./app-utils";

// Get all sticky elements
const stickyElements = document.querySelectorAll<HTMLElement>(".sticky-top");

// Create a placeholder element after each sticky element
stickyElements.forEach((x) => {
  x.insertAdjacentHTML("afterend", '<div class="sticky-top-placeholder"></div>');
});

// Update the state of the sticky elements when the window is scrolled or resized
const updateStickState = debounce(() => {
  const isScrolled = window.scrollY > 50;
  stickyElements.forEach((x) => x.classList.toggle("stuck-top", isScrolled));
}, 10);
window.addEventListener("scroll", updateStickState);
window.addEventListener("resize", updateStickState);
