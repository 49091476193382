import { debounce } from "./app-utils";

export function initializeScrollPositionTracking() {
  document.querySelectorAll<HTMLElement>("[data-scroll-percentage-x]").forEach((element) => {
    initializeScrollPositionTrackingForElement(element, "X");
  });
  document.querySelectorAll<HTMLElement>("[data-scroll-percentage-y]").forEach((element) => {
    initializeScrollPositionTrackingForElement(element, "Y");
  });
}

function initializeScrollPositionTrackingForElement(element: HTMLElement, direction: "X" | "Y") {
  // Define a callback to update the scroll position on the element
  const dataProp = "scrollPercentage" + direction;
  const scrollSizeProp = direction === "X" ? "scrollWidth" : "scrollHeight";
  const clientSizeProp = direction === "X" ? "clientWidth" : "clientHeight";
  const positionProp = direction === "X" ? "scrollLeft" : "scrollTop";
  const storeScroll = () => {
    const scrollSize = element[scrollSizeProp];
    const clientSize = element[clientSizeProp];
    const scrollPosition = element[positionProp];
    const scrollPercentage = (scrollPosition / (scrollSize - clientSize)) * 100;
    element.dataset[dataProp] = scrollPercentage.toFixed(0);
  };

  // Update the scroll position now, and also on scroll and resize events
  // NOTE: Make sure to debounce the scroll event using animation frames, and set use the 'passive' option
  element.addEventListener("scroll", debounce(storeScroll, 100), { passive: true });
  window.addEventListener("resize", debounce(storeScroll, 100), { passive: true });
  storeScroll();
}
