/**
 * Set values for CSS variables --vh and --100vh based on the current window height. This value is updated when the
 * window height changes, but only in increments of at least 100px (this is to avoid jumping when the viewport is
 * resized due to the URL bar being hidden/shown when scrolling on mobile).
 */
(function () {
  // Set initial values for the variables
  let viewportHeight: number;
  const documentStyle = document.documentElement.style;
  updateViewportHeightVars();

  // Update the value for the viewport height CSS variables any time the viewport height changes by more than 100px
  window.addEventListener("resize", () => {
    if (Math.abs(document.documentElement.clientHeight - viewportHeight) > 100) {
      updateViewportHeightVars();
    }
  });

  /**
   * Update the viewport height CSS variables based on the current viewport height.
   */
  function updateViewportHeightVars() {
    viewportHeight = document.documentElement.clientHeight;
    documentStyle.setProperty("--100vh", viewportHeight.toString() + "px");
    documentStyle.setProperty("--vh", (viewportHeight / 100).toString() + "px");
  }
})();
