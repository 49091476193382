/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered. The function will be
 * called on both the leading and trailing edges of the timeout.
 *
 * Based on: https://davidwalsh.name/javascript-debounce-function
 *
 * @param func The function to execute when the timeout elapses.
 * @param wait The timeout duration.
 * @return The generated function.
 */
export function debounce<TArgs extends unknown[]>(func: (...args: TArgs) => void, wait: number) {
  let timeout: number | undefined;
  return function (this: unknown, ...args: TArgs) {
    // Define a function to call after the timeout
    const later = () => {
      timeout = undefined;
      func.apply(this, args);
    };

    // Call now if the timeout has not been set yet
    if (timeout === undefined) func.apply(this, args);

    // Reset the timeout to wait another 'wait' milliseconds
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
