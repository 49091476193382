import "bootstrap"; // NOTE: It would be better to just import the parts of Bootstrap that we need, but it doesn't work correctly for some reason.
import "lazysizes";

import { initializeContactForms } from "./contact-form";
import "./fixed-viewport-height";
import "./scroll-link";
import "./sticky-top";
import { initializeScrollPositionTracking } from "./track-scroll-position";

initializeContactForms();
initializeScrollPositionTracking();
