/**
 * Declare gtag as a global variable to avoid TypeScript errors
 */
declare const gtag: (...args: unknown[]) => void;

/**
 * If this page has any contact forms, show the form and add event listeners to handle any form submissions.
 *
 * NOTE: The form is hidden on page load and only shown once we know the user has JavaScript enabled.
 */
export function initializeContactForms() {
  const forms = document.querySelectorAll<HTMLFormElement>("form.contact-form");
  forms.forEach((form) => {
    form.classList.remove("d-none");
    form.addEventListener("submit", (e) => void handleFormSubmit(e));
  });
}

/**
 * Handle the submission of a contact form
 */
async function handleFormSubmit(event: SubmitEvent) {
  // Prevent default form submission handling
  event.preventDefault();

  try {
    // Get the form and fieldset elements
    const form = event.target as HTMLFormElement;
    const fieldset = form.querySelector("fieldset")!;

    // Report the form submission to Google Analytics
    // NOTE: It' not super clear whether this is necessary or whether this is just double-reporting form submissions
    // The automatic form submission detection may already be reporting this event, but it isn't clear
    // Using the Tag Assistant tool (https://tagassistant.google.com/) gives conflicting results that the automatic event
    // is fired, but only reported to Google Ads, not GA4
    if (typeof gtag === "function") {
      gtag("event", "form_submit", {
        form_id: form.id,
        form_name: form.name,
      });
    }

    // Serialize the form data to JSON
    const formData = new FormData(form);
    const submission = Object.fromEntries(formData.entries());
    const body = JSON.stringify(submission);

    // Disable the fieldset
    // NOTE: This has to be done after disabling the form (disabled field are not submitted)
    fieldset.disabled = true;

    try {
      // Submit the form data
      const response = await fetch("/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
      });

      // Validate the response code
      if (!response.ok) {
        const message = `Contact form submission failed with response code ${response.status.toFixed()} (${response.statusText})`;
        throw new Error(message);
      }

      // Replace the form contents with a success message; keep the form the same height and center the message
      form.style.height = `${form.offsetHeight.toString()}px`;
      form.innerHTML = `
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="alert alert-info text-center m-0" role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-check-circle mb-3" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
          <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
        </svg>
        <p class='m-0'>Thank you for your submission! We will get back to you as soon as possible.</p>
      </div>
    </div>`;
    } finally {
      // Re-enable the form fieldset
      fieldset.disabled = false;
    }
  } catch (error) {
    console.error("An error occurred submitting the contact form", error);
    alert(
      "There was an error submitting the form. We apologize for the inconvenience. Please try again, or contact us directly via email.",
    );
  }
}
